<template>
    <Layout>
        <div class="epa_auth">
            <div class="epa_auth_window">

                <form @submit.prevent="login">
                    <div class="error" v-if="form.errors.email" v-text="form.errors.email"></div>
                    <input type="email" v-model="form.email" name="email" id="email" :placeholder="$t('Email')">
                    <input type="password" v-model="form.password" name="password" id="password" :placeholder="$t('Password')">
                    <EpaButton class="form" loading="true" type="submit" :disabled="form.processing">{{ $t('Log in') }}</EpaButton>
                </form>

                <div class="auth_links">
                    <EpaButton class="link small primary" :href="route('password.forgot')">{{ $t('Forgot password?') }}</EpaButton>
                </div>

                <img class="badeend" src="../../../img/badeend.svg"/>

            </div>
            <img class="epa_icon" src="../../../img/epa_icon.svg"/>
        </div>
    </Layout>
</template>

<script setup>

import Layout from "../../components/Layout/Layout.vue";
import {router, useForm} from "@inertiajs/vue3";
import EpaButton from "../../components/UI/EpaButton.vue";

defineProps({ errors: Object })

const form = useForm({
    email: 'r.terheide@epartment.nl',
    password: 'password',
});

function login() {
    form.post(route('login'));
}

</script>

